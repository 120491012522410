const colors = {
  LIGHT_BLUE: '#6ac7e0',
  ATT_BLUE: '#001B2E',
  WHITE: '#ffffff',
  BLACK: '#000000',
  LIGHT_GREY: "#f4f5f6",
  GREY: "#808080",
  DARK_GREY: "#656a72",
  TURQUOISE: '#00ddae',
  YELLOW: '#ffff00'
};

const sizes = {
  XXX_SMALL: '12px',
  XX_SMALL: '14px',
  X_SMALL: '18px',
  SMALL: '20px',
  MEDIUM: '25px',
  LARGE: '30px',
  X_LARGE: '43px',
  XX_LARGE: '50px',
  XXX_LARGE: '60px'
};

export { colors, sizes };